import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
function navLink(route, text) {
    var location = useLocation();
    var pN = location.pathname;
    return (React.createElement("div", { className: "nav-link" },
        React.createElement(Link, { to: route, className: "" + (pN == route ? "active" : "") }, text)));
}
function mobileNavLink(route, text) {
    var location = useLocation();
    var pN = location.pathname;
    return (React.createElement("div", { className: "mobile-nav-link" },
        React.createElement(Link, { to: route, className: "" + (pN == route ? "active" : "") }, text)));
}
export default function TopNav() {
    var _a = useState(false), mobileOpen = _a[0], setMobileOpen = _a[1];
    var d = new Date();
    return (React.createElement("div", { className: "row top-nav" },
        React.createElement("div", { className: "desktop-nav" },
            React.createElement("div", { className: "container" },
                React.createElement(Link, { className: "nav-logo", to: "/" },
                    React.createElement("img", { src: "/images/ideablock-logo.png" })),
                React.createElement("div", { className: "nav-list" },
                    navLink("/", "Home"),
                    navLink("/product", "Product"),
                    navLink("/deployment", "Deployment"),
                    navLink("/comparison", "Comparison"),
                    navLink("/detail", "Detail")))),
        React.createElement("div", { className: "mobile-nav " + (mobileOpen ? "open" : "") },
            React.createElement("span", { onClick: function () { return setMobileOpen(!mobileOpen); }, className: "mobile-toggle" }, mobileOpen ? React.createElement("img", { src: "/images/close.png" }) : React.createElement("img", { src: "/images/hamburger.png" })),
            React.createElement(Link, { className: "nav-logo", to: "/" },
                React.createElement("img", { src: "/images/ideablock-logo.png" })),
            React.createElement("span", { className: "mobile-nav-bump" }),
            React.createElement("div", { className: "mobile-menu bg-blue" },
                mobileNavLink("/", "Home"),
                mobileNavLink("/product", "Product"),
                mobileNavLink("/deployment", "Deployment"),
                mobileNavLink("/comparison", "Comparison"),
                mobileNavLink("/detail", "Detail"),
                React.createElement("div", { className: "mobile-submenu" },
                    React.createElement("div", { className: "list-item" },
                        React.createElement(Link, { to: "/detail#faqs" }, "FAQs")),
                    React.createElement("div", { className: "list-item" },
                        React.createElement(Link, { to: "/detail#about" }, "About")),
                    React.createElement("div", { className: "list-item" },
                        React.createElement("a", { href: "mailto:hello@ideablock.io" }, "Contact"))),
                React.createElement("div", { className: "mobile-social t-center" },
                    React.createElement("a", { target: "_blank", href: "https://www.facebook.com/ldeablock/" },
                        React.createElement("img", { src: "/images/icons/facebook.svg" })),
                    React.createElement("a", { target: "_blank", href: "https://linkedin.com/company/ideablock" },
                        React.createElement("img", { src: "/images/icons/linkedin.svg" })),
                    React.createElement("a", { target: "_blank", href: "https://twitter.com/ldeablock" },
                        React.createElement("img", { src: "/images/icons/twitter.svg" }))),
                React.createElement("div", { className: "mobile-footer" },
                    "\u00A9 IdeaBlock ", "" + d.getFullYear(),
                    ". All rights reserved.")))));
}
