var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var NewsletterForm = /** @class */ (function (_super) {
    __extends(NewsletterForm, _super);
    function NewsletterForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NewsletterForm.prototype.render = function () {
        return (React.createElement("div", { id: "newsLetter", className: "row bg-green newsletter-form" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-6 col-12" },
                        React.createElement("h1", { className: "font-alt" },
                            "Get the latest IP ",
                            React.createElement("br", null),
                            " thought leadership")),
                    React.createElement("form", { action: "https://usebasin.com/f/537e59e81435", method: "POST", className: "col-lg-6 col-12 email-input" },
                        React.createElement("input", { name: "emailnews", id: "emailnews", placeholder: "Your Email", type: "email" }),
                        React.createElement("button", { type: "submit" },
                            React.createElement("img", { src: "/images/arrow.png" })))))));
    };
    return NewsletterForm;
}(React.Component));
export default NewsletterForm;
