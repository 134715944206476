var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import WatchPreviewButton from 'components/button/watchPreview';
import DemoForm from 'components/forms/demo';
import NewsletterForm from 'components/forms/newsletter';
import Footer from 'components/nav/footer';
import TopNav from 'components/nav/nav';
import React, { useEffect, useState } from 'react';
var Product = /** @class */ (function (_super) {
    __extends(Product, _super);
    function Product() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Product.prototype.render = function () {
        return (React.createElement("div", { className: "container-fluid product-page" },
            React.createElement(TopNav, null),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-1 font-white t-center" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h1", { className: "font-alt" },
                                "Secure Market Access Forever. ",
                                React.createElement("br", null),
                                "With a Click.")),
                        React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12" },
                            React.createElement("p", null, "Ideablock leverages the power of cryptography to forge the strongest possible foundation for a successful IP future. ")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement(WatchPreviewButton, null,
                                React.createElement("i", { className: "play-btn" }),
                                " PREVIEW")),
                        React.createElement("div", { className: "col-12 content-image", style: { position: "relative" } },
                            React.createElement("img", { className: "img-fluid", src: "/images/fullmonitor_dash.png" }),
                            React.createElement("img", { className: "info-img info-img-1", src: "/images/chart_one.png" }),
                            React.createElement("img", { className: "info-img info-img-2", src: "/images/pie_one.png" }))))),
            React.createElement("div", { className: "row section-blue" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-2 font-white", style: { position: "relative" } },
                        React.createElement("div", { className: "col-12 t-center" },
                            React.createElement("h1", { className: "font-alt" }, "The Ideablock Advantage")),
                        React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12 t-center font-dark" },
                            React.createElement("p", { className: "text-bf" }, "Instantly identify, review, and secure all of your intangible assets while helping your bottom line.")),
                        React.createElement("div", { className: "row advantage-list" },
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/shield.svg" })),
                                React.createElement("h2", { className: "font-alt" }, "Secure"),
                                React.createElement("p", null, "Rest easy knowing that the world's most advanced encryption standards are guarding all of your assets.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/protect.svg" })),
                                React.createElement("h2", { className: "font-alt" }, "Intelligent"),
                                React.createElement("p", null, "Use an intuitive tagging system and full text search to keep each and every asset at your fingertips.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/predict.svg" })),
                                React.createElement("h2", { className: "font-alt" }, "Measurable"),
                                React.createElement("p", null, "Gain deep knowledge of innovation activity across your organization with our management dashboard.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/blockchain2.svg" })),
                                React.createElement("h2", { className: "font-alt" }, "Predictive"),
                                React.createElement("p", null, "Optimize future decision-making with access to the full history of R&D activity and outcomes."))),
                        React.createElement(Carousel, null)))),
            React.createElement("div", { className: "row bg-white" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-3" },
                        React.createElement("h4", { style: { letterSpacing: "2px", marginBottom: "1em" }, className: "font-blue t-center" }, "UPGRADE TO EASY"),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12 t-center font-alt" },
                                React.createElement("h1", { className: "font-alt" }, "Plan for the future."),
                                React.createElement("h1", { className: "font-alt" }, "Reduce risk."),
                                React.createElement("h1", { className: "font-alt" }, "Grow faster."))),
                        React.createElement("div", { className: "row advantage-list-2" },
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/blockchain3.svg" })),
                                React.createElement("h2", null, "Improved communication across the organization")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/coin2.svg" })),
                                React.createElement("h2", null, "Transform all of your teams into revenue drivers")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/network2.svg" })),
                                React.createElement("h2", null, "Insight into what's next\u2014right now, not later")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/file2.svg" })),
                                React.createElement("h2", null, "Boost legal operations from as-is to light speed")))))),
            React.createElement(DemoForm, null),
            React.createElement(NewsletterForm, null),
            React.createElement(Footer, null)));
    };
    return Product;
}(React.Component));
export default Product;
function Carousel() {
    var _a = useState(0), slide = _a[0], setSlide = _a[1];
    var _b = useState(false), slideSet = _b[0], activeSlide = _b[1];
    useEffect(function () {
        var mounted = true;
        setTimeout(function () {
            if (mounted == false) {
                return;
            }
            if (slideSet) {
                return;
            }
            setSlide(slide == 2 ? 0 : slide + 1);
        }, 6000);
        return function () { mounted = false; };
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row carousel", style: { position: "relative" } },
            React.createElement("div", { className: "col-lg-6 order-lg-2 col-12 slide-image", style: { position: "relative" } }, slides.map(function (s, index) {
                return (React.createElement("img", { key: index, src: s.image, className: "" + (slide == index ? "active" : "") }));
            })),
            React.createElement("div", { className: "col-lg-6 col-12  order-lg-1 slide-text" },
                slides.map(function (s, index) {
                    return (React.createElement("div", { key: index, className: "slide " + (slide == index ? "active" : "") },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-xl-8 col-12" },
                                React.createElement("h1", { className: "font-alt font-white" }, s.heading),
                                React.createElement("p", { className: "font-dark text-bf" }, s.text)))));
                }),
                React.createElement("div", { className: "carousel-controls" },
                    React.createElement("span", { onClick: function () { setSlide(0); activeSlide(true); }, className: "carousel-position " + (slide == 0 ? "active" : "") }),
                    React.createElement("span", { onClick: function () { setSlide(1); activeSlide(true); }, className: "carousel-position " + (slide == 1 ? "active" : "") }),
                    React.createElement("span", { onClick: function () { setSlide(2); activeSlide(true); }, className: "carousel-position " + (slide == 2 ? "active" : "") })))),
        React.createElement("span", { className: "carousel-index" },
            "0",
            slide + 1)));
}
var slides = [
    {
        "heading": "First-to-prove is the new first-to-market",
        "text": "Secure all of your IP on blockchain through Ideablock, \n        protecting your first-mover position for everything you\u2019ve invested in, \n        even for IP that hasn\u2019t made it through the patent process yet.",
        "image": "/images/product_preview_assets.png",
    },
    {
        "heading": "Data-driven IP business intelligence",
        "text": "Annual and quarterly departmental reports are retrospective. \n        Now, own a current window into all your IP and control over what happens next.",
        "image": "/images/product_preview_home.png",
    },
    {
        "heading": "Simplify workflows and reduce manual errors.",
        "text": "Self-service analytics at enterprise scale.",
        "image": "/images/product_preview_projects.png",
    },
];
