var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import WatchPreviewButton from 'components/button/watchPreview';
import AltDemoForm from 'components/forms/demoalt';
import Footer from 'components/nav/footer';
import TopNav from 'components/nav/nav';
import React, { createRef } from 'react';
var Detail = /** @class */ (function (_super) {
    __extends(Detail, _super);
    function Detail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Detail.prototype.render = function () {
        return (React.createElement("div", { className: "container-fluid detail-page" },
            React.createElement(TopNav, null),
            React.createElement("div", { className: "row section-1-wrapper" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-1 font-white" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-12 col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" }, "Supercharge innovation with the only blockchain IP protection platform.")),
                            React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12 t-center" },
                                React.createElement("p", null, "Ideablock streamlines existing legal processes from invention submission to product launch, providing the strongest available form of defensive IP protection."))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 t-center" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("h4", { style: { letterSpacing: "2px", marginBottom: "1em" }, className: "font-blue t-center" }, "IDEABLOCK TECH"))),
                        React.createElement("div", { className: "row detail-list" },
                            React.createElement("div", { className: "col-lg-4 col-12 list-item t-center" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/coin2.svg" })),
                                React.createElement("h2", null, "Bulletproof Basis"),
                                React.createElement("p", null, "The Ideablock infrastructure establishes undeniable proof of invention. Using the most advanced blockchain technology ever available, Ideablock proves when you uploaded your idea, instantly and forever.")),
                            React.createElement("div", { className: "col-lg-4 col-12 list-item t-center" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/blockchain3.svg" })),
                                React.createElement("h2", null, "Simple Access"),
                                React.createElement("p", null, "Managing user permissions for all of your organization\u2019s assets is finally pain free. Quickly and easily create teams, assign administrators, and automate your existing invention submission flow to jumpstart collaboration while minimizing potential data bleed.")),
                            React.createElement("div", { className: "col-lg-4 col-12 list-item t-center" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/network2.svg" })),
                                React.createElement("h2", null, "Secure Infrastructure"),
                                React.createElement("p", null, "Ideablock blankets your most sensitive business data with military-grade encryption from the moment it enters the platform. Rest easy knowing that all of your information resides in a completely private and overly secure digital vault."))),
                        React.createElement("div", { className: "content-lower t-center" },
                            React.createElement("hr", { className: "tp" }),
                            React.createElement(WatchPreviewButton, null,
                                React.createElement("i", { className: "play-btn" }),
                                " WATCH PREVIEW"))))),
            React.createElement("div", { className: "row bg-white" },
                React.createElement("div", { className: "container faqs-container" },
                    React.createElement("div", { className: "row content-section section-2 faqs", id: "faqs" },
                        React.createElement("div", { className: "col-12 t-center" },
                            React.createElement("h2", { className: "font-alt heading" }, "Frequently Asked Questions")),
                        React.createElement("div", { className: "col-12 faqs-list" },
                            React.createElement(FAQ, { Question: "Why do I need Ideablock?" },
                                React.createElement("p", null, "Traditionally, protecting an idea has involved securing one of the traditional forms of government-protected IP\u2013patents, trademarks, copyrights, and trade secrets. The processes for obtaining these types of IP, however, were created in a world that looked much different than the one inventors and businesses currently inhabit\u2013where technology meant pistons and smoke, not qubits and clouds. With the speed of innovation vastly outpacing the ability of this old IP system to keep up, innovators often find themselves in limbo, unsure as to whether their most valuable assets are truly protected or if their investments are just one cease-and-desist letter or court ruling away from vanishing into thin air. Ideablock provides a new form of security for your ideas: proof that you possessed an idea and a verifiable record of when it was available to the public. Verifiable prior art insurance for the 21st century.")),
                            React.createElement(FAQ, { Question: "What is a Blockchain?" },
                                React.createElement("p", null, "A blockchain is a distributed ledger agreed upon by the participant nodes that make up a decentralized computer network. Every node on this network has a copy of the transaction ledger, which leverages strong cryptographic hashes to prove the validity of the data associated with each transaction on the ledger. In addition to storing data associated with transactions on the ledger, blockchains can be used to store any information at all, including cryptographic hashes that mathematically prove the existence or presence of some data or event. The numbers underpinning cryptographic hashes\u2013including SHA-256, which is the hash algorithm utilized by Ideablock\u2013are so big and the chances of the same hash representing two separate files are so small that the authenticity of idea data and its upload timestamp (and any updates) can be mathematically proven. This math underlying cryptography, as well as the ultimately limited processing power in the known universe, ensures that these blockchains cannot be forged or altered by bad actors, rendering the truth of any data that is validated on the blockchain unassailable and mathematically provable.")),
                            React.createElement(FAQ, { Question: "How is Ideablock different than a patent?" },
                                React.createElement("p", null, "The holder of a valid U.S. patent can enforce their patent offensively by suing others that infringe their patent claims. Although an Ideablock idea cannot be used to initiate an infringement suit, anyone can utilize an Ideablock idea to nullify any later-filed U.S. patent that is asserted against them or any third party.")),
                            React.createElement(FAQ, { Question: "But isn\u2019t the U.S. First-to-File now?" },
                                React.createElement("p", null, "Yes, in 2012 and 2013 the United States adopted patent filing rules that gave legal priority to the earliest application filed on any new idea. The \"race to the patent office,\" however, has no bearing on the form of IP protection Ideablock provides. Unlike the offensive right given to patent-holders to patrol any relevant market for patent infringers, Ideablock provides defensive tools to its users\u2013namely, a way to ensure that, even if the user does not seek to obtain traditional forms of IP to protect an idea, no other individual or entity will be able to obtain a patent that could be used in the future to block the user from developing the idea and eventually taking the idea to market. This is because no one can obtain or legally enforce a wrongly issued patent when (1) the idea was conceived of by another inventor before the corresponding patent application was filed and (2) the idea was made available to the public prior to the filing of that application. Until now, no similar means for inventors and businesses to instantly create unassailable prior art has existed. So not only does Ideablock offer the first-ever service for undeniably establishing prior art, it does so instantly.")),
                            React.createElement(FAQ, { Question: "Why would I make my IP available to the public?" },
                                React.createElement("p", null, "We know\u2013it seems counterintuitive at first, doesn't it? But consider this: the only form of IP that does NOT require full disclosure of the underlying idea as a prerequisite for protection is a trade secret. In other words, full public disclosure of the underlying idea will be compulsory for any inventor who wishes to obtain most traditional forms of IP protection. And not only is full disclosure ultimately obligatory, under the first-to-file system, those who delay verifiable public disclosure, through Ideablock or otherwise, risk another individual or entity filing for IP protection first\u2013which would foreclose any chance for the earlier inventor to obtain IP protection for their idea. If it helps to ease any remaining uneasiness, know that Ideablock does not publish or otherwise disclose the identity of the user or business entity that uploaded the idea by default.")))))),
            React.createElement("div", { className: "row bg-3", id: "about" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row content-section section-3 font-white" },
                        React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12 t-center" },
                            React.createElement("h1", { className: "font-alt" }, "Built by industry veterans with decades of legal and technical experience.")),
                        React.createElement("div", { className: "container the-team" },
                            React.createElement("div", { className: "row team-member" },
                                React.createElement("div", { className: "col-lg-4 col-12 t-center head" },
                                    React.createElement("img", { className: "img-fluid", src: "/images/headshots/eli.png" })),
                                React.createElement("div", { className: "col-lg-8 col-12" },
                                    React.createElement("h2", { className: "font-blue name" }, "Eli Sheets"),
                                    React.createElement("h3", { className: "title font-alt" }, "CEO"),
                                    React.createElement("p", { className: "bio" },
                                        "      \u200B",
                                        React.createElement("br", null),
                                        "Eli is a seasoned patent attorney and developer with over a decade of experience in the intellectual property world. Before founding Ideablock, Eli practiced IP law at some of the country\u2019s leading law firms, helping clients large and small to secure their intangible assets.  He has written, filed, and prosecuted more than 500 patent applications.",
                                        React.createElement("br", null),
                                        "      \u200B",
                                        React.createElement("br", null),
                                        "Before practicing IP law, Eli studied electrical and computer engineering at MIT and UW-Madison and began his professional career as a patent examiner at the U.S. Patent and Trademark Office. In law school, Eli served as the editor-in-chief for one of the world's preeminent IP law publications and clerked for federal judges at the trial court and appeals court levels.",
                                        React.createElement("br", null),
                                        "      \u200B",
                                        React.createElement("br", null),
                                        "Eli lives in Raleigh, North Carolina with his wife and their four young boys.")))),
                        React.createElement("div", { className: "container detail-logos" },
                            React.createElement("div", { className: "logo-column" },
                                React.createElement("img", { src: "/images/detail/mit.png" })),
                            React.createElement("div", { className: "logo-column" },
                                React.createElement("img", { src: "/images/detail/uspto.png" })),
                            React.createElement("div", { className: "logo-column" },
                                React.createElement("img", { src: "/images/detail/seal.png" })),
                            React.createElement("div", { className: "logo-column" },
                                React.createElement("img", { src: "/images/detail/qualcomm.png" })),
                            React.createElement("div", { className: "logo-column" },
                                React.createElement("img", { src: "/images/detail/nh.png" })))))),
            React.createElement(AltDemoForm, null),
            React.createElement(Footer, null)));
    };
    return Detail;
}(React.Component));
export default Detail;
var FAQ = /** @class */ (function (_super) {
    __extends(FAQ, _super);
    function FAQ(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            open: false
        };
        _this.contentRef = createRef();
        _this.toggleOpen = _this.toggleOpen.bind(_this);
        return _this;
    }
    FAQ.prototype.toggleOpen = function () {
        if (this.state.open) {
            this.contentRef.current.style.height = "0";
        }
        else {
            this.contentRef.current.style.height = this.contentRef.current.scrollHeight + 'px';
        }
        this.setState(function (prev) { return ({
            open: !prev.open
        }); });
    };
    FAQ.prototype.render = function () {
        return (React.createElement("div", { className: "row faqs-list-item " + (this.state.open ? "open" : "") },
            React.createElement("div", { className: "col-12 heading", onClick: this.toggleOpen },
                React.createElement("i", { className: "arrow" }),
                " ",
                React.createElement("span", { className: "question" },
                    " ",
                    this.props.Question)),
            React.createElement("div", { className: "col-12 content", ref: this.contentRef }, this.props.children)));
    };
    return FAQ;
}(React.Component));
