var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Home from 'pages/home';
import Product from 'pages/product';
import Comparison from 'pages/comparison';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import Deployment from 'pages/deployment';
import Detail from 'pages/detail';
import VideoModal from 'components/content/videomodal';
import { Provider } from 'react-redux';
import createAppStore, { store } from 'redux/store';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
var firebaseConfig = {
    apiKey: "AIzaSyDlo4ZBw-815bVJ9jUdVnIOvyV_LY22L9Q",
    authDomain: "ideablock-website.firebaseapp.com",
    projectId: "ideablock-website",
    storageBucket: "ideablock-website.appspot.com",
    messagingSenderId: "896474561951",
    appId: "1:896474561951:web:9c0723a72b5a68554491aa",
    measurementId: "G-K6GTB625RQ"
};
var app = initializeApp(firebaseConfig);
var analytics = getAnalytics(app);
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        return (React.createElement(Router, null,
            React.createElement(ScrollHandler, null,
                React.createElement(VideoModal, null),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/deployment", component: Deployment }),
                    React.createElement(Route, { path: "/product", component: Product }),
                    React.createElement(Route, { path: "/comparison", component: Comparison }),
                    React.createElement(Route, { path: "/detail", component: Detail }),
                    React.createElement(Route, { path: "/", component: Home })))));
    };
    return App;
}(React.Component));
var ScrollHandlerLocal = /** @class */ (function (_super) {
    __extends(ScrollHandlerLocal, _super);
    function ScrollHandlerLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScrollHandlerLocal.prototype.componentDidMount = function () {
        if (this.props.location.hash != "") {
            if ('scrollRestoration' in history) {
                window.history.scrollRestoration = 'manual';
            }
            var elem = document.getElementById(this.props.location.hash.replace("#", ""));
            if (elem != undefined && elem != null) {
                elem.scrollIntoView();
            }
        }
        else {
            if ('scrollRestoration' in history) {
                window.history.scrollRestoration = 'auto';
            }
        }
    };
    ScrollHandlerLocal.prototype.componentDidUpdate = function (prev) {
        if (this.props.location !== prev.location && this.props.location.hash == "") {
            window.scrollTo(0, 0);
        }
        if (this.props.location.hash != "") {
            if ('scrollRestoration' in history) {
                window.history.scrollRestoration = 'manual';
            }
            var elem = document.getElementById(this.props.location.hash.replace("#", ""));
            if (elem != undefined && elem != null) {
                elem.scrollIntoView();
            }
        }
        else {
            if ('scrollRestoration' in history) {
                window.history.scrollRestoration = 'auto';
            }
        }
    };
    ScrollHandlerLocal.prototype.render = function () {
        return this.props.children;
    };
    return ScrollHandlerLocal;
}(React.Component));
var ScrollHandler = withRouter(ScrollHandlerLocal);
//Matthew pls.
var INITIAL_STATE = {
    VideoOpen: false,
};
createAppStore(INITIAL_STATE);
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(App, null)), document.getElementById("root"));
