var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import WatchPreviewButton from 'components/button/watchPreview';
import DemoForm from 'components/forms/demo';
import NewsletterForm from 'components/forms/newsletter';
import Footer from 'components/nav/footer';
import TopNav from 'components/nav/nav';
import React from 'react';
import { Link } from 'react-router-dom';
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Home.prototype.render = function () {
        return (React.createElement("div", { className: "container-fluid home-page" },
            React.createElement(TopNav, null),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-1 font-white" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h1", { className: "font-alt" }, "Instant IP Protection is Here. It's About Time."))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: " col-12" },
                                React.createElement("p", null, "Supercharge your innovation engine with the only platform that protects all intellectual property as you manage it.  Ideablock helps companies maximize value from invention through product launch\u2014and every moment in between."))),
                        React.createElement("div", { className: "content-lower" },
                            React.createElement(WatchPreviewButton, null,
                                React.createElement("i", { className: "play-btn" }),
                                " PREVIEW"),
                            React.createElement("span", { className: "btn-separator" }),
                            React.createElement("a", { href: "#demoForm", className: "btn btn-green-alt font-alt" }, "REQUEST DEMO"))),
                    React.createElement("div", { className: "shapes" },
                        React.createElement("img", { src: "images/rocket.png", className: "shape-1" }),
                        React.createElement("img", { src: "images/cone.png", className: "shape-2" }),
                        React.createElement("img", { src: "images/triangle.png", className: "shape-3" })),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "company-list col-xl-8 col-lg-8 col-12" })))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-2 font-white content-right" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 content-image" },
                                React.createElement("img", { src: "images/secure.png" })),
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 content-text" },
                                React.createElement("h1", { className: "font-alt" }, "Secure Your Entire R&D Pipeline in Real Time"),
                                React.createElement("p", null, "Every asset added to the Ideablock platform is instantly timestamped using the world's most powerful blockchain technology, forging an indisputable record that shrinks future legal risk and drives business value."),
                                React.createElement("div", { className: "content-lower" },
                                    React.createElement(Link, { className: "link-green", to: "/product" }, "Learn More"))),
                            React.createElement("span", { className: "vertical-bump" }))))),
            React.createElement("div", { className: "row section-blue", style: { zIndex: 5, position: "relative" } },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-3 section-full content-left" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 order-xl-2 order-lg-2 content-image" },
                                React.createElement("img", { src: "images/streamline.png" })),
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 order-xl-1 order-lg-1 content-text" },
                                React.createElement("h1", { className: "font-alt font-white" },
                                    "Streamline Early-Stage IP",
                                    React.createElement("br", null),
                                    "Operations from Day One"),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-lg-10 col-12" },
                                        React.createElement("p", null,
                                            React.createElement("b", null, "Seamlessly capture, review, and manage company-wide innovation in a single intuitive web-based interface that easily integrates into your internal IP process."))))))))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-4 section-full" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 content-image" },
                                React.createElement("img", { src: "images/browser_sm.png" })),
                            React.createElement("div", { className: "col-xl-6 col-lg-6 col-12 content-text" },
                                React.createElement("h1", { className: "font-alt font-white" }, "Build a Data-Driven IP Strategy"),
                                React.createElement("p", { className: "font-white" }, "Ideablock arms organizations with granular intelligence to track productivity, visualize project history, drive team engagement, and optimize best practices for maximizing value capture."),
                                React.createElement("div", { className: "content-lower" },
                                    React.createElement(Link, { className: "link-green", to: "/deployment" }, "Learn More"))))),
                    React.createElement("span", { className: "section-4-vertical-bump" })),
                React.createElement("img", { src: "images/blockpattern.svg", className: "section-4-backing" })),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-5" },
                        React.createElement("div", { className: "row" },
                            React.createElement("h4", { style: { letterSpacing: "2px", marginBottom: "1em" }, className: "col-12 font-blue t-center" }, "BETTER WITH IDEABLOCK"),
                            React.createElement("div", { className: "col-12 content-text" },
                                React.createElement("h1", { className: "font-alt font-white t-center" },
                                    "Simple to Use.",
                                    React.createElement("br", null),
                                    "Simply Protected."),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-md-8 offset-md-2 col-12" },
                                        React.createElement("p", { className: "font-white t-center" }, "Using Ideablock, users can secure any idea in only a few clicks. Just select one or more files that describe the idea and Ideablock takes care of the rest."))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 t-center" },
                                        React.createElement(WatchPreviewButton, null,
                                            React.createElement("i", { className: "play-btn" }),
                                            " WATCH PREVIEW")),
                                    React.createElement("div", { className: "col-12 content-image", style: { position: "relative" } },
                                        React.createElement("img", { className: "img-fluid", src: "/images/monitor_assets.png" }),
                                        React.createElement("img", { className: "info-img info-img-1", src: "/images/chart_one.png" }),
                                        React.createElement("img", { className: "info-img info-img-2", src: "/images/pie_one.png" })))))))),
            React.createElement("div", { className: "row bg-3" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-6" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-xl-7 col-lg-7 col-12 order-xl-1 content-text font-white" },
                                React.createElement("h4", { style: { letterSpacing: "2px", marginBottom: "1em" }, className: "font-blue" }, "IP IS IN OUR DNA"),
                                React.createElement("h1", { className: "font-alt" }, "Built by industry veterans with decades of legal and technical experience."),
                                React.createElement("p", null),
                                React.createElement("div", { className: "content-lower" },
                                    React.createElement(Link, { className: "link-green", to: "/detail#about" }, "Learn More"))),
                            React.createElement("div", { className: "col-xl-5 col-lg-5 col-12 order-xl-2 content-image" },
                                React.createElement("img", { src: "images/experience.png" })))))),
            React.createElement(DemoForm, null),
            React.createElement(NewsletterForm, null),
            React.createElement(Footer, null)));
    };
    return Home;
}(React.Component));
export default Home;
