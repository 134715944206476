export function AppReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case 'TOGGLE_MODAL':
            if (state.VideoOpen) {
                document.body.classList.remove("modal-open");
            }
            else {
                document.body.classList.add("modal-open");
            }
            return Object.assign({}, state, {
                VideoOpen: !state.VideoOpen
            });
        default:
            return state;
    }
}
