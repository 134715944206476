var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Link } from 'react-router-dom';
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Footer.prototype.render = function () {
        var d = new Date();
        return (React.createElement("div", { className: "row footer font-white bg-2" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "footer-upper row" },
                    React.createElement("div", { className: "col-md-6 col-12" },
                        React.createElement(Link, { to: "/" },
                            React.createElement("img", { className: "footer-mark", src: "/images/mark.png" }),
                            " ")),
                    React.createElement("div", { className: "col-md-6 col-12" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-4 footer-list" },
                                React.createElement("span", { className: "list-heading" }, "Technology"),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement(Link, { to: "/detail#faqs", className: "link-green" }, "FAQs")),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement("a", { target: "_blank", href: "https://beta.ideablock.io", className: "link-green" }, "Try Beta"))),
                            React.createElement("div", { className: "col-4 footer-list" },
                                React.createElement("span", { className: "list-heading" }, "Company"),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement(Link, { to: "/detail#about", className: "link-green" }, "About")),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement("a", { target: "_blank", href: "https://www.youtube.com/channel/UC5p19fcSRv6BFeQZzflXDlA/videos", className: "link-green" }, "Resources"))),
                            React.createElement("div", { className: "col-4 footer-list" },
                                React.createElement("span", { className: "list-heading" }, "Support"),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement("a", { href: "mailto:hello@ideablock.io", className: "link-green" }, "Contact")),
                                React.createElement("div", { className: "list-item" },
                                    React.createElement("a", { href: "https://github.com/ideablock", target: "_blank", className: "link-green" }, "Github")))))),
                React.createElement("div", { className: "footer-lower row" },
                    React.createElement("div", { className: "left col-6" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-6 col-12" },
                                React.createElement("span", { className: "copyright-time" },
                                    "      \u00A9 Ideablock ", "" + d.getFullYear(),
                                    ". All rights reserved. ")),
                            React.createElement("div", { className: "col-lg-6 col-12" },
                                React.createElement("a", { target: "_blank", href: "https://beta.ideablock.io/privacy", className: "link-white" }, "Privacy Policy"),
                                React.createElement("a", { target: "_blank", href: "https://beta.ideablock.io/terms", className: "link-white" }, "Terms of Use")))),
                    React.createElement("div", { className: "right col-6" },
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/ldeablock/" },
                            React.createElement("img", { src: "/images/icons/facebook.svg" })),
                        React.createElement("a", { target: "_blank", href: "https://linkedin.com/company/ideablock" },
                            React.createElement("img", { src: "/images/icons/linkedin.svg" })),
                        React.createElement("a", { target: "_blank", href: "https://twitter.com/ldeablock" },
                            React.createElement("img", { src: "/images/icons/twitter.svg" })))))));
    };
    return Footer;
}(React.Component));
export default Footer;
