var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Footer from 'components/nav/footer';
import TopNav from 'components/nav/nav';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
var Deployment = /** @class */ (function (_super) {
    __extends(Deployment, _super);
    function Deployment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Deployment.prototype.render = function () {
        return (React.createElement("div", { className: "container-fluid deployment-page" },
            React.createElement(TopNav, null),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-1 font-white" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" }, "Get unparalleled visibility and protection with plug-and-play setup."))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-6 offset-lg-3 col-12 t-center" },
                                React.createElement("p", null, "Ideablock deployment is risk-free with minimal IT impact and can transform traditional IP protection processes at scale."))),
                        React.createElement("div", { className: "row deployment-list" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                        React.createElement("h2", null,
                                            React.createElement("img", { src: "/images/coin.svg" })),
                                        React.createElement("h2", { className: "font-alt" }, "Deploy in Parallel"),
                                        React.createElement("p", null, "Integration across your entire business designed to be stood up without disrupting any of your in-flight IP.")),
                                    React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                        React.createElement("h2", null,
                                            React.createElement("img", { src: "/images/blockchain.svg" })),
                                        React.createElement("h2", { className: "font-alt" },
                                            "Train ", "&",
                                            " Transfer"),
                                        React.createElement("p", null, "Quickly and easily ramp team engagement with a user experience that is a light lift from start to completion.")),
                                    React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                        React.createElement("h2", null,
                                            React.createElement("img", { src: "/images/file.svg" })),
                                        React.createElement("h2", { className: "font-alt" }, "Track Real-Time"),
                                        React.createElement("p", null, "Eliminate tag lag by leveraging fresh data to make informed decisions about allocations of resources and investments.")),
                                    React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                        React.createElement("h2", null,
                                            React.createElement("img", { src: "/images/network.svg" })),
                                        React.createElement("h2", { className: "font-alt" }, "Ramp Output"),
                                        React.createElement("p", null, "Measure, predict, and incentivize performance from your brain trust, then watch as the value follows.")))))))),
            React.createElement("div", { className: "row section-blue font-white" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-2" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" }, "Rocket fuel for team output across the whole organization."))),
                        React.createElement(DeploymentInfo, null)))),
            React.createElement("div", { className: "row section-blue font-white", style: { zIndex: 100 } },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-3" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" },
                                    "Finally de-fog your entire R", "&",
                                    "D windshield from day one."))),
                        React.createElement("div", { className: "row deployment-advantage-list" },
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/shield.svg" })),
                                React.createElement("h2", null, "Ironclad security, out-of-the-box"),
                                React.createElement("p", { className: "font-dark" }, "End-to-end encryption for all of your data, comes standard.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/protect.svg" })),
                                React.createElement("h2", null, "Hindsight-perfect protection"),
                                React.createElement("p", { className: "font-dark" }, "Strong, base-layer protection for all intangible assets.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/predict.svg" })),
                                React.createElement("h2", null, "Predictive investment power"),
                                React.createElement("p", { className: "font-dark" }, "Gain access to historical R&D data to inform future planning.")),
                            React.createElement("div", { className: "col-lg-3 col-6 list-item" },
                                React.createElement("h2", null,
                                    React.createElement("img", { src: "/images/blockchain2.svg" })),
                                React.createElement("h2", null, "Productivity-based retention"),
                                React.createElement("p", { className: "font-dark" }, "Granular productivity information enables optimal decision-making.")))))),
            React.createElement("div", { className: "row desktop-tree bg-white mobile-hide" },
                React.createElement("div", { className: "tree-wrapper" },
                    React.createElement("img", { src: "/images/tree/flat_left.png", className: "tree-shape tree-left-3" }),
                    React.createElement("img", { src: "/images/tree/flat_left.png", className: "tree-shape tree-left-2" }),
                    React.createElement("img", { src: "/images/tree/curved_left.png", className: "tree-shape tree-left-1" }),
                    React.createElement("img", { src: "/images/tree/center.png", className: "tree-shape tree-center" }),
                    React.createElement("img", { src: "/images/tree/curved_right.png", className: "tree-shape tree-right-1" }),
                    React.createElement("img", { src: "/images/tree/flat_right.png", className: "tree-shape tree-right-2" }),
                    React.createElement("img", { src: "/images/tree/flat_right.png", className: "tree-shape tree-right-3" }),
                    React.createElement("h2", { className: "font-alt tree-object tree-object-1-heading" }, "Access vault-level protection & accountability"),
                    React.createElement("img", { className: "tree-object tree-object-1-main-img", src: "/images/tree/deploy_status.svg" }),
                    React.createElement("img", { className: "tree-object tree-object-1-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "tree-object font-alt tree-object-2-heading" }, "A thicker legal wall, on demand"),
                    React.createElement("img", { className: "tree-object tree-object-2-main-img", src: "/images/tree/deploy_hash.svg" }),
                    React.createElement("img", { className: "tree-object tree-object-2-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "tree-object font-alt tree-object-3-heading" }, "Advantage in data, trends, and pattern-matching"),
                    React.createElement("img", { className: "tree-object tree-object-3-main-img", src: "/images/tree/deploy_trends.svg" }),
                    React.createElement("img", { className: "tree-object tree-object-3-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "tree-object font-alt tree-object-4-heading" }, "First-to-know results per employee, team, and research line"),
                    React.createElement("img", { className: "tree-object tree-object-4-main-img", src: "/images/tree/deploy_results.svg" }),
                    React.createElement("img", { className: "tree-object tree-object-4-green-bar", src: "images/tree/green_bar.png" }))),
            React.createElement("div", { className: "row mobile-tree bg-white desktop-hide" },
                React.createElement("div", { className: "mobile-tree-wrapper" },
                    React.createElement("h2", { className: "font-alt mobile-tree-object-1-heading" }, "Access vault-level protection & accountability"),
                    React.createElement("img", { className: "mobile-tree-object-1-main-img", src: "/images/tree/deploy_status.svg" }),
                    React.createElement("img", { className: "mobile-tree-object-1-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "font-alt mobile-tree-object-2-heading" }, "A thicker legal wall, on demand"),
                    React.createElement("img", { className: "mobile-tree-object-2-main-img", src: "/images/tree/deploy_hash.svg" }),
                    React.createElement("img", { className: "mobile-tree-object-2-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "font-alt mobile-tree-object-3-heading" }, "Advantage in data, trends, and pattern-matching"),
                    React.createElement("img", { className: "mobile-tree-object-3-main-img", src: "/images/tree/deploy_trends.svg" }),
                    React.createElement("img", { className: "mobile-tree-object-3-green-bar", src: "images/tree/green_bar.png" }),
                    React.createElement("h2", { className: "font-alt mobile-tree-object-4-heading" }, "First-to-know results per employee, team, and research line"),
                    React.createElement("img", { className: "mobile-tree-object-4-main-img", src: "/images/tree/deploy_results.svg" }),
                    React.createElement("img", { className: "mobile-tree-object-4-green-bar", src: "images/tree/green_bar.png" }))),
            React.createElement("div", { className: "row tree-bottom bg-white" },
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("a", { href: "/#demoForm", className: "btn btn-green font-alt" }, "SCHEDULE YOUR DEMO TODAY"))),
            React.createElement(Footer, null)));
    };
    return Deployment;
}(React.Component));
export default Deployment;
function DeploymentInfo() {
    var _a = useState("operations"), activeView = _a[0], setActiveView = _a[1];
    return (React.createElement("div", { className: "deployment-info" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "deployment-menu row" },
                React.createElement("div", { onClick: function () { return setActiveView("operations"); }, className: "col-lg-3 col-6 t-center menu-item " + (activeView == "operations" ? "active" : "") + " " }, "Operations"),
                React.createElement("div", { onClick: function () { return setActiveView("engineering"); }, className: "col-lg-3 col-6 t-center menu-item " + (activeView == "engineering" ? "active" : "") + " " }, "Engineering"),
                React.createElement("div", { onClick: function () { return setActiveView("finance"); }, className: "col-lg-3 col-6 t-center menu-item " + (activeView == "finance" ? "active" : "") + " " }, "Finance"),
                React.createElement("div", { onClick: function () { return setActiveView("legal"); }, className: "col-lg-3 col-6 t-center menu-item " + (activeView == "legal" ? "active" : "") + " " }, "Legal")),
            React.createElement("div", { className: "deployment-content" }, activeView == "operations" ? DeploymentOperations() :
                activeView == "engineering" ? DeploymentEngineering() :
                    activeView == "finance" ? DeploymentFinance() :
                        activeView == "legal" ? DeploymentLegal() : null),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement(Link, { to: "/comparison", className: "btn btn-green font-alt" }, "READ MORE"))))));
}
function DeploymentLegal() {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-2 deployment-image" },
            React.createElement("img", { src: "/images/deploy_legal.svg", className: "img-fluid" })),
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-1 text-content" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h1", { className: "font-alt" }, "The IP protection, review, and strategy tool made for a virtual world."),
                React.createElement("h3", null, "THE NEW SECRET WEAPON FOR YOUR LEGAL ARSENAL"),
                React.createElement("p", { className: "font-dark text-bf" }, "Instantly identify, review, and secure all of your intangible assets while helping your bottom line.")))));
}
function DeploymentFinance() {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-2 deployment-image" },
            React.createElement("img", { src: "/images/bargraph.svg", className: "img-fluid" })),
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-1 text-content" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h1", { className: "font-alt" }, "Lay claim to next-gen revenue streams by ensuring future market participation."),
                React.createElement("h3", null, "EXPERIENCE FULL RETURN ON R&D INVESTMENT"),
                React.createElement("p", { className: "font-dark text-bf" }, "Ideablock allows organizations to capture the full value on all innovation, including those ideas typically left on the cutting room floor.")))));
}
function DeploymentEngineering() {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-2 deployment-image" },
            React.createElement("img", { src: "/images/deploy_graphic_eng.svg", className: "img-fluid" })),
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-1 text-content" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h1", { className: "font-alt" }, "Drive inventor engagement with fully trackable and provable attribution."),
                React.createElement("h3", null, "PROJECT MANAGEMENT AND IP PROTECTION BECOME ONE"),
                React.createElement("p", { className: "font-dark text-bf" }, "Ideablock takes the time sink, confusion, and anxiety out of internal IP processes so your innovators can focus on innovation and leave the law to legal.")))));
}
function DeploymentOperations() {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-2 deployment-image" },
            React.createElement("img", { src: "/images/deploy_operations.png", className: "img-fluid" })),
        React.createElement("div", { className: "col-lg-6 col-12 order-lg-1 text-content" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("h1", { className: "font-alt" }, "Transform revenue pipelines from lumbering to limber with zero lag."),
                React.createElement("h3", null, "Serving CIO, COO, and PM missions"),
                React.createElement("p", { className: "font-dark text-bf" }, "Ideablock arms organizations with telescopic intelligence and bulletproof protection for all of its intangible assets - from creation to vetting to launch.")))));
}
