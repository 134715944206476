import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//Local imports 
import { AppReducer } from './app';
export var store = null;
export default function createAppStore(initialState) {
    var state = initialState;
    var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    var enhancers = composeEnhancers(applyMiddleware(thunk));
    store = createStore(AppReducer, state, enhancers);
}
