var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DemoForm from 'components/forms/demo';
import NewsletterForm from 'components/forms/newsletter';
import Footer from 'components/nav/footer';
import TopNav from 'components/nav/nav';
import React, { useState } from 'react';
var Comparison = /** @class */ (function (_super) {
    __extends(Comparison, _super);
    function Comparison() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Comparison.prototype.render = function () {
        return (React.createElement("div", { className: "container-fluid comparison-page" },
            React.createElement(TopNav, null),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-1 font-white" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" }, "Upgrade to a data-driven strategy that secures all your intellectual property. ")),
                            React.createElement("div", { className: "col-lg-8 offset-lg-2 col-12 t-center" },
                                React.createElement("p", null, "With high stakes and a morphing corporate environment, IP protection separates market leaders from market laggards.")))),
                    React.createElement("div", { className: "comparison-shapes" },
                        React.createElement("img", { src: "images/rocket_white.png", className: "shape-1" }),
                        React.createElement("img", { src: "images/cone_white.png", className: "shape-2" }),
                        React.createElement("img", { src: "images/triangle_white.png", className: "shape-3" })))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-2 font-white pathing-top mobile-hide" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6 left" },
                                        React.createElement("img", { src: "/images/comparison_path_upper.png" })),
                                    React.createElement("div", { className: "col-6 right" },
                                        React.createElement("img", { src: "/images/comparison_path_upper.png" }))))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("span", { className: "comparison-tag tag-left font-alt" }, "TREASURE HUNT"),
                                        React.createElement("h3", { className: "font-white comparison-heading" },
                                            "The manual, ",
                                            React.createElement("br", null),
                                            " unpredictable, old-",
                                            React.createElement("br", null),
                                            "fashioned way.")),
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("span", { className: "comparison-tag font-alt" }, "GOLD MINE"),
                                        React.createElement("h3", { className: "font-white comparison-heading" },
                                            "The modern, instantly-",
                                            React.createElement("br", null),
                                            "defensible future-",
                                            React.createElement("br", null),
                                            "proofing solution."))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("span", { className: "vertical-dashed-bar" })),
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("span", { className: "vertical-bar" })))))),
                    React.createElement(ComparisonContent, null),
                    React.createElement("div", { className: "font-white pathing-bottom mobile-hide" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6 left" },
                                        React.createElement("img", { src: "/images/pathing_lower_left.png" })),
                                    React.createElement("div", { className: "col-6 right" },
                                        React.createElement("img", { src: "/images/comparison_path_lower.png" })))))))),
            React.createElement("div", { className: "row bg-white" },
                React.createElement("img", { src: "/images/blockpattern_2.png", className: "mobile-hide section-3-blockpattern" }),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "content-section section-3" },
                        React.createElement("span", { className: "mobile-hide section-3-blockpattern-bump" }),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 t-center mobile-hide" },
                                React.createElement("span", { className: "comparison-tag", style: { letterSpacing: "4px", fontSize: "14px", marginBottom: "2em" } }, "USE CASES")),
                            React.createElement("div", { className: "col-lg-10 offset-lg-1 col-12 t-center" },
                                React.createElement("h1", { className: "font-alt" }, "Simplify your IP process and reduce time-to-market.")),
                            React.createElement(UseCases, null))))),
            React.createElement("span", { id: "demo" }),
            React.createElement(DemoForm, { DarkMode: true }),
            React.createElement(NewsletterForm, null),
            React.createElement(Footer, null)));
    };
    return Comparison;
}(React.Component));
export default Comparison;
function UseCases() {
    var _a = useState("pharma"), activeView = _a[0], setActiveView = _a[1];
    return (React.createElement("div", { className: "col-12 use-cases" },
        React.createElement("div", { className: "row header" },
            React.createElement("div", { className: "menu-item col-lg-3 col-6 " + (activeView == "pharma" ? "active" : ""), onClick: function () { return setActiveView("pharma"); } },
                React.createElement("span", null, "Pharmaceuticals")),
            React.createElement("div", { className: "menu-item col-lg-3 col-6 " + (activeView == "software" ? "active" : ""), onClick: function () { return setActiveView("software"); } },
                React.createElement("span", null, "Software")),
            React.createElement("div", { className: "menu-item col-lg-3 col-6 " + (activeView == "telecom" ? "active" : ""), onClick: function () { return setActiveView("telecom"); } },
                React.createElement("span", null, "Telecom")),
            React.createElement("div", { className: "menu-item col-lg-3 col-6 " + (activeView == "electronics" ? "active" : ""), onClick: function () { return setActiveView("electronics"); } },
                React.createElement("span", null, "Semiconductors"))),
        React.createElement("div", { className: "row content" },
            React.createElement("div", { className: "col-lg-6 col-12 use-case-img" },
                React.createElement("img", { src: useCases[activeView].image })),
            React.createElement("div", { className: "col-lg-6 col-12 use-case-text" },
                React.createElement("p", null, useCases[activeView].text))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12 t-center" },
                React.createElement("hr", { className: "tp" }),
                React.createElement("hr", { className: "tp" }),
                React.createElement("hr", { className: "tp" }),
                React.createElement("a", { href: "#demo", className: "btn btn-green font-alt" }, "GET A TECHNICAL DEEP DIVE")))));
}
function ComparisonContent() {
    var _a = useState("trade-secrets"), activeView = _a[0], setActiveView = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "comparison-content row mobile-hide" },
            React.createElement("div", { className: "col-lg-1 t-right", style: { position: "relative" } },
                React.createElement("div", { className: "side-menu" },
                    React.createElement("div", { onClick: function () { return setActiveView("trade-secrets"); }, className: "menu-item " + (activeView == "trade-secrets" ? "active" : "") },
                        "Trade ",
                        React.createElement("br", null),
                        " ",
                        React.createElement("u", null, "Secrets")),
                    React.createElement("div", { onClick: function () { return setActiveView("trolls"); }, className: "menu-item " + (activeView == "trolls" ? "active" : "") },
                        React.createElement("u", null, "Trolls")),
                    React.createElement("div", { onClick: function () { return setActiveView("time"); }, className: "menu-item " + (activeView == "time" ? "active" : "") },
                        React.createElement("u", null, "Time")),
                    React.createElement("div", { onClick: function () { return setActiveView("rd"); }, className: "menu-item " + (activeView == "rd" ? "active" : "") },
                        "R", "&",
                        "D ",
                        React.createElement("br", null),
                        " ",
                        React.createElement("u", null, "Investment")),
                    React.createElement("div", { onClick: function () { return setActiveView("ip"); }, className: "menu-item " + (activeView == "ip" ? "active" : "") },
                        "Internal IP ",
                        React.createElement("br", null),
                        " ",
                        React.createElement("u", null, "Processes")))),
            React.createElement("div", { className: "col-lg-10" },
                React.createElement("div", { className: "row", style: { position: "relative" } },
                    React.createElement("div", { className: "col-6 t-center" },
                        React.createElement("div", { className: "comparison-attribute" },
                            React.createElement("div", { className: "comparison-attribute-content font-white " + activeView },
                                React.createElement("img", { src: comparisonLeft[activeView].image }),
                                React.createElement("ul", null, comparisonLeft[activeView].text.map(function (item, index) {
                                    return (React.createElement("li", { key: index }, item));
                                }))))),
                    React.createElement("span", { className: "comparison-vs font-white" }, "VS"),
                    React.createElement("div", { className: "col-6 t-center" },
                        React.createElement("div", { className: "comparison-attribute" },
                            React.createElement("div", { className: "comparison-attribute-content " + activeView },
                                React.createElement("img", { src: "images/group-4.svg" }),
                                React.createElement("ul", null, comparisonRight[activeView].text.map(function (item, index) {
                                    return (React.createElement("li", { key: index, className: "font-green" }, item));
                                })))))))),
        React.createElement("div", { className: "comparison-content-mobile desktop-hide row font-white" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "col-12 mobile-list" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { onClick: function () { return setActiveView("trade-secrets"); }, className: "menu-item " + (activeView == "trade-secrets" ? "active" : "") },
                            "Trade ",
                            React.createElement("br", null),
                            " ",
                            React.createElement("u", null, "Secrets")),
                        React.createElement("div", { onClick: function () { return setActiveView("trolls"); }, className: "menu-item " + (activeView == "trolls" ? "active" : "") },
                            React.createElement("u", null, "Trolls")),
                        React.createElement("div", { onClick: function () { return setActiveView("time"); }, className: "menu-item " + (activeView == "time" ? "active" : "") },
                            React.createElement("u", null, "Time")),
                        React.createElement("div", { onClick: function () { return setActiveView("rd"); }, className: "menu-item " + (activeView == "rd" ? "active" : "") },
                            "R", "&",
                            "D ",
                            React.createElement("br", null),
                            " ",
                            React.createElement("u", null, "Investment")),
                        React.createElement("div", { onClick: function () { return setActiveView("ip"); }, className: "menu-item " + (activeView == "ip" ? "active" : "") },
                            "Internal IP ",
                            React.createElement("br", null),
                            " ",
                            React.createElement("u", null, "Processes")))),
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("span", { className: "comparison-tag comparison-tag-mobile font-alt" }, "TREASURE HUNT"),
                    React.createElement("h3", { className: "font-white mobile-comparison-heading" }, "The manual, unpredictable, old-fashioned way.")),
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("div", { className: "comparison-attribute" },
                        React.createElement("div", { className: "comparison-attribute-content font-white " + activeView },
                            React.createElement("img", { src: comparisonLeft[activeView].image }),
                            React.createElement("ul", null, comparisonLeft[activeView].text.map(function (item, index) {
                                return (React.createElement("li", { key: index }, item));
                            }))))),
                React.createElement("div", { className: "col-12 t-center mobile-vs-wrapper" },
                    React.createElement("span", { className: "comparison-vs-mobile" }, "VS")),
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("span", { className: "comparison-tag comparison-tag-mobile font-alt" }, "GOLD MINE")),
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("h3", { className: "font-white mobile-comparison-heading" }, "The modern, instantly-defensible future-proofing solution")),
                React.createElement("div", { className: "col-12 t-center" },
                    React.createElement("div", { className: "comparison-attribute" },
                        React.createElement("div", { className: "comparison-attribute-content " + activeView },
                            React.createElement("img", { src: "images/group-4.svg" }),
                            React.createElement("ul", null, comparisonRight[activeView].text.map(function (item, index) {
                                return (React.createElement("li", { key: index, className: "font-green" }, item));
                            })))))))));
}
var useCases = {
    "pharma": {
        "image": "/images/usecases/pharma.png",
        "text": "The average R&D spend to bring a single drug formulation to market is around a billion dollars. \n        During this process, tens of millions are spent to develop candidate formulations that are ultimately scrapped \u2014 \n        valuable IP that is left completely unprotected. With Ideablock, pharmaceutical firms can instantly recapture the \n        full value of their R&D investment and retain the right to capitalize on the future market value of all IP created \n        during the drug development life-cycle.",
    },
    "software": {
        "image": "/images/usecases/software.png",
        "text": "In the span of just a few decades, software has become an essential component in almost every business sector. \n        The importance of securing software IP has skyrocketed in kind. Unfortunately, over the same time it has become increasingly \n        difficult to secure software innovation with traditional forms of IP. Ideablock arms users with tools that make software IP \n        protection easy again.  Strengthen your existing version control workflow with Ideablock Commit\u2122, our intuitive git integration. \n        Or use Ideablock CLI\u2122 to secure your code without leaving the terminal. We prefer easy, too.",
    },
    "telecom": {
        "image": "/images/usecases/telecom.png",
        "text": "With the proliferation of IoT ongoing and the deployment of 5G well underway, the demand for device communication \n        technologies promises to remain sky-high for the foreseeable future. And as investment and revenues continue to pour into \n        the world of telecommunications, the race to be the first to innovate becomes quicker and more consequential for each and \n        every market player. Ideablock empowers the most innovative companies in communications to prove they were first, reserving \n        their right to access their increasingly lucrative markets.",
    },
    "electronics": {
        "image": "/images/usecases/electronics.png",
        "text": " If there is a single industry that most exemplifies the adage that law trails technology, it very well may be that of \n        integrated circuit design and manufacturing.  For years, the industry has been forced to attempt to apply old laws meant for a \n        macroscopic world to device structures that can only be seen with a microscope. Not an easy job by any measure. Ideablock uses technology \n        to help bridge that gap between the old legal world and bleeding-edge semiconductor core IP\u2014and to help its users remain protected as they \n        innovate. Let us help you drive your future forward, today..",
    }
};
var comparisonLeft = {
    "trade-secrets": {
        "image": "/images/group-2.svg",
        "text": ['Build Taller Fences', 'Collaboration Quagmire', 'Prior Use Paradox'],
    },
    "trolls": {
        "image": "/images/icon_trolls.svg",
        "text": ['Products Held Hostage', 'Troll Toll', 'Business Disrupted'],
    },
    "time": {
        "image": "/images/icon_time.svg",
        "text": ['Years of Limbo', 'Go-to-Market Unprotected', 'Rely on Availability of Outside Counsel'],
    },
    "rd": {
        "image": "/images/icon_money.svg",
        "text": ['Crystal Ball', 'Paper', 'Sunk Cost'],
    },
    "ip": {
        "image": "/images/icon_process.svg",
        "text": ['Siloed', 'Limited Information Availability', 'Strategic Decision-making by Gut'],
    }
};
var comparisonRight = {
    "trade-secrets": {
        "text": ['Digital Fortress', 'Communication Enabled', 'Prior Use Proven'],
    },
    "trolls": {
        "text": ['Complete Freedom to Operate', 'Trolls Controlled', 'Business as Usual'],
    },
    "time": {
        "text": ['Instant Defensive Protection', 'Commercial Activity Fully Defended', 'Instantly Build Foundational Defense'],
    },
    "rd": {
        "text": ['Protect It All', 'Blockchain', 'Zero Loss']
    },
    "ip": {
        "text": ['Centralized Company-Wide IP Hub', 'Real-Time, Granular, Provable Statistics', 'Data-Driven Strategy'],
    }
};
