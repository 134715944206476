var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { connect } from 'react-redux';
import { store } from 'redux/store';
var mapState = function (state) { return ({
    VideoOpen: state.VideoOpen,
}); };
var connector = connect(mapState, null);
var VideoModalLocal = /** @class */ (function (_super) {
    __extends(VideoModalLocal, _super);
    function VideoModalLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.videoRef = React.createRef();
        return _this;
    }
    VideoModalLocal.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "video-modal " + (this.props.VideoOpen ? "open" : ""), onClick: function () { store.dispatch({ type: "TOGGLE_MODAL" }); _this.videoRef.current.src = ""; _this.videoRef.current.src = "https://player.vimeo.com/video/515824543"; } },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "video-wrapper" },
                        React.createElement("iframe", { ref: this.videoRef, src: "https://player.vimeo.com/video/515824543", width: "560", height: "349", frameBorder: "0", allow: "autoplay; fullscreen; picture-in-picture", allowFullScreen: true }))))));
    };
    return VideoModalLocal;
}(React.Component));
var VideoModal = connector(VideoModalLocal);
export default VideoModal;
