var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var DemoForm = /** @class */ (function (_super) {
    __extends(DemoForm, _super);
    function DemoForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DemoForm.prototype.render = function () {
        return (React.createElement("div", { id: "demoForm", className: "row " + (this.props.DarkMode ? "bg-3 font-white" : "bg-white") + " demo-form" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "heading t-center font-alt" }, "Let's Innovate Together"),
                        React.createElement("h4", { className: "sub-heading t-center" }, "Find out how we bring the power of blockchain to IP security. "))),
                React.createElement("form", { action: "https://usebasin.com/f/6d752c74c4d6", method: "POST", className: "row input-section" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-6 col-md-6 col-12" },
                                React.createElement("input", { name: "name", id: "name", type: "text", placeholder: "Name" })),
                            React.createElement("div", { className: "col-lg-6 col-md-6 col-12" },
                                React.createElement("input", { type: "email", id: "email", name: "email", placeholder: "Corporate Email" }))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-6 col-md-6 col-12" },
                                React.createElement("input", { type: "text", name: "role", id: "role", placeholder: "Role" })),
                            React.createElement("div", { className: "col-lg-6 col-md-6 col-12" },
                                React.createElement("input", { type: "text", name: "reason", id: "reason", placeholder: "Reason" })))),
                    React.createElement("div", { className: "col-12 t-center" },
                        React.createElement("button", { type: "submit", className: "btn btn-green" }, "REQUEST PRIVATE DEMO"))))));
    };
    return DemoForm;
}(React.Component));
export default DemoForm;
